if ($(document).find('.page.services').length !== 0) {
  $('.services__row').each((i, element) => {
    if ($(element).find('.services__item').length === 3) {
      $(element).find('.services__item').addClass('col-1');
    } else if ($(element).find('.services__item').length === 2) {
      $(element).find('.services__item').eq(0).addClass('col-1');
      $(element).find('.services__item').eq(1).addClass('col-2');
    } else {
      $(element).find('.services__item').addClass('col-3');
    }
  });
}