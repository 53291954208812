import './vendor';
import './helpers';
import './pages/main';
import './pages/services';
import './pages/works';
import videojs from 'video.js';

$('.video-js').each((i, element) => {
  let $this = $(element);
  let id = $this.attr('id');
  let $full = $this.closest('.video-full');
  let $info = $this.closest('.single-news__topside').find('.single-news__topside__info');

  let video = videojs(id, {
    controls: $this.data('controls'),
    loop: $this.data('loop'),
    muted: $this.data('muted'),
    autoplay: $this.data('autoplay'),
    poster: $this.data('poster'),
  })

  video.src([
    {
      type: 'video/mp4',
      src: $this.data('video'),
    }
  ]);

  video.on('pause', () => {
    console.log($full, $full.hasClass('video-bg'), $full.hasClass('can-bg'))
    if ($full &&
      $full.hasClass('video-bg') &&
      $full.hasClass('can-bg')
    ) {
      $full.addClass('is-paused');
      $info.removeClass('no-active').addClass('can-click');
    }
  });

  video.on('play', () => {
    if ($full &&
      $full.hasClass('video-bg') &&
      $full.hasClass('can-bg')
    ) {
      $full.removeClass('is-paused');
      $info.find('.single-news__topside__info').addClass('no-active').removeClass('can-click');
    }
  });
});

$('.cursor-container').on('mouseenter', () => {
  $('.cursor').show();
});

$('.cursor-container').on('mouseleave', () => {
  $('.cursor').hide();
});

$('.cursor-container').on('mousemove', (e) => {
  if (innerWidth > 1024) {
    $(e.currentTarget).parent().find('.cursor').css({
      top: e.pageY - $(e.currentTarget).offset().top,
      left: e.pageX - $(e.currentTarget).offset().left,
    });
  }
});

$('.single-news__topside__info').each((i, e) => {
  $(e).css({
    height: `${$(e).parent().innerHeight()}px`
  });
});

$(window).on('resize', () => {
  if ($('.single-news__topside__info').length) {
    $('.single-news__topside__info').each((i, e) => {
      $(e).css({
        height: `${$(e).parent().innerHeight()}px`
      });
    });
  }
});

$('.video-preview').on('click', (e) => {
  $(e.currentTarget).hide();
  let $video = $(e.currentTarget).parent().find('.video-full');

  $video.find('video').get(0).play();
  $video.addClass('can-bg');

  if ($(e.currentTarget).closest('.single-news__topside')) {
    $(e.currentTarget).closest('.single-news__topside').find('.single-news__topside__info').addClass('no-active').removeClass('can-click');
  }

  if ($video.closest('.col-video').length > 0) {
    $video.closest('.col-video').addClass('played');
  }

  if ($(document).find('.page.main').length) {
    $video.closest('.main__video').addClass('played');
  }
});

$('.single-news__topside__info').on('click', (e) => {
  $(e.currentTarget).addClass('no-active').removeClass('can-click');
  let $video = $(e.currentTarget).parent().find('.video-full');

  $video.find('video').get(0).play();
  $video.addClass('can-bg').removeClass('is-paused');
});

$('.header__burger').on('click', () => {
  $('.header').toggleClass('is-active');
  $('.header__center').fadeToggle();
});

let ru;
let uz;
let en;
let activeLangIndex = 0;

$('.langs').find('li').each((i, e) => {
  if (i === 0) {
    ru = $(e).find('a');
  }

  if (i === 1) {
    uz = $(e).find('a');
  }

  if (i === 2) {
    en = $(e).find('a');
  }

  if ($(e).hasClass('wpml-ls-current-language')) {
    activeLangIndex = i;
  }
});

$('.langs').html('');

$('.langs').append(ru).append(uz).append(en);

$(document).find('.langs a').each((i, e) => {
  if (i === activeLangIndex) {
    $(e).addClass('is-active');
  }

  if (i === 0) {
    $(e).text('ру');
  } else if (i === 1) {
    $(e).text('eng');
  } else {
    $(e).text('o’zb');
  }
});

if ($('.site-container').data('color')) {
  const color = $('.site-container').data('color');
  const bg = $('.site-container').data('bg');

  $(document).find('h1, h2, h3, h4, h5, h6, p, a, span, input, li')
    .not('.langs a')
    .attr('style', `color: ${color};`);
  $(document).find('svg').attr('style', `fill: ${color};`);
  $(document).find('input').attr('style', `color: ${color}; border-color: ${color};`);
  $(document).find('div').not('.site-container').attr('style', `border-color: ${color};`);
  $('.header, .site-container').attr('style', `background-color: ${bg};`);
  $(document).find('.langs a.is-active').attr('style', `color: ${color};`);
}
