import select2 from 'select2';

if ($(document).find('.page.works').length) {
  $('.js-select').each((i, element) => {
    $(element).select2({
      minimumResultsForSearch: -1,
      dropdownAutoWidth: true,
      dropdownParent: $(element).closest('.filters__item'),
    });
  });

  $('.filters__button').on('click', (e) => {
    e.preventDefault();

    const serviceData = $(document).find('.select-service').val();
    const sphereData = $(document).find('.select-sphere').val();
    let currentUrl = `${location.href.split('/works')[0]}/works/`;
    let url = currentUrl;
    let lang = '';

    if (location.href.indexOf('lang=en') !== -1) {
      lang = '&lang=en'
    }

    if (location.href.indexOf('lang=uz') !== -1) {
      lang = '&lang=uz'
    }

    if (serviceData && serviceData !== '') {
      url = `${currentUrl}?filter-service=${serviceData}${lang}`;
    }

    if (sphereData && sphereData !== '') {
      if (serviceData && serviceData !== '') {
        url = `${currentUrl}?filter-service=${serviceData}&filter-sphere=${sphereData}${lang}`;
      } else {
        url = `${currentUrl}?filter-sphere=${sphereData}${lang}`;
      }
    }

    location.href = url;
  });

  $('.js-select').on('change', (e) => {
    if ($(document).find('.select-service').val() !== '' || $(document).find('.select-sphere').val() !== '') {
      $('.filters__button').removeClass('not-active');
    } else {
      $('.filters__button').addClass('not-active');
    }
  });

  $('.filters a').on('click', (e) => {
    e.preventDefault();

    let currentUrl = `${location.href.split('/works')[0]}/works/`;
    let lang = '';

    if (location.href.indexOf('lang=en') !== -1) {
      lang = '?lang=en'
    }

    if (location.href.indexOf('lang=uz') !== -1) {
      lang = '?lang=uz'
    }

    currentUrl += lang

    location.href = currentUrl;
  });
}