if ($(document).find('.page.main').length !== 0) {
  $(document).on('scroll', () => {
    if ($(document).scrollTop() >= $('.main__works').offset().top - 150 &&
      $(document).scrollTop() <= $('.main__works').offset().top + $('.main__works').height() - 100
    ) {
      $('.main__works').addClass('is-dark');
    } else {
      $('.main__works').removeClass('is-dark');
    }
  });
}
